import * as React from "react"
import { useState } from 'react';
import Layout from "../components/layout"
import Seo from "../utils/seo"
import { Row, Col } from "react-bootstrap"
import "./temple.scss"
import { Link } from 'gatsby';
import PropTypes from 'prop-types';
import {
  Configure,
  Highlight,
  Hits,
  InstantSearch,
  Pagination,
  RefinementList,
  SearchBox,
  ClearRefinements,
  CurrentRefinements,
  ToggleRefinement,
} from 'react-instantsearch-hooks-web';
import { history } from 'instantsearch.js/es/lib/routers';
import { simple } from 'instantsearch.js/es/lib/stateMappings';
import algoliasearch from 'algoliasearch/lite';
import { MdSearch } from 'react-icons/md';
import Slideshow from "../utils/Slideshow";
import { FaLeaf } from 'react-icons/fa';
// import cardcover from "../images/tszshan.jpg"

const searchClient = algoliasearch(
  'KXIVY6UESQ',
  'a0b73064ab583c0d451e56e865021fdc'
);

const routing = {
  router: history(),
  stateMapping: simple(),
};

function App() {
  const [isOpen, setIsOpen] = useState(false);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  }

  return (
    <Layout>

      <Slideshow />
      <InstantSearch searchClient={searchClient} indexName="prod_metagain" routing={routing}>
        <Configure hitsPerPage={12} />
        <Row>
          <Col md={3}>
            <div>
              <button className="search-category-button" onClick={toggleDropdown}>
                分類選擇
              </button>
            </div>

            <div className={`${isOpen ? 'search-category-list' : 'search-category-list hide'}`}>
              <div className="search-category-container-header">
                分類
              </div>
              <div className="search-category-container">
                <RefinementList attribute="pray_category" limit={5} showMore={true}
                  translations={{
                    showMoreButtonText({ isShowingMore }) {
                      return isShowingMore ? '顯示更少' : '顯示更多';
                    },
                  }}
                />
              </div>
              <div className="search-category-container-header">
                地區
              </div>
              <div className="search-category-container">
                <RefinementList attribute="location_up" limit={10} showMore={true}
                  translations={{
                    showMoreButtonText({ isShowingMore }) {
                      return isShowingMore ? '顯示更少' : '顯示更多';
                    },
                  }}
                />
              </div>
              <div className="search-category-container-header">
                神祇
              </div>
              <div className="search-category-container">
                <RefinementList attribute="deity_up" limit={10} showMore={true}
                  translations={{
                    showMoreButtonText({ isShowingMore }) {
                      return isShowingMore ? '顯示更少' : '顯示更多';
                    },
                  }}
                />
              </div>
              <div className="search-category-container-header">
                節誕
              </div>
              <div className="search-category-container">
                <RefinementList attribute="activity_up" limit={5} showMore={true}
                  translations={{
                    showMoreButtonText({ isShowingMore }) {
                      return isShowingMore ? '顯示更少' : '顯示更多';
                    },
                  }}
                />
              </div>
              <ClearRefinements translations={{ resetButtonText: '重設', }} />
            </div>
          </Col>

          <Col md={9}>

            <Row className="search-sort-container">
              <Col>
                <div className="temple-search-container">
                  <MdSearch className="temple-search-icon" />
                  <SearchBox placeholder="搜尋..." />
                  <ToggleRefinement attribute="vegetarian_food_provided" label="供應齋菜" />

                </div>
              </Col>
            </Row>

            <CurrentRefinements excludedAttributes={['vegetarian_food_provided']} transformItems={items => items.filter(item => item.attribute !== 'featured')} />



            <Hits hitComponent={Hit} />

            <div className="pagination-container">
              <Pagination showLast={false} />
            </div>

          </Col>
        </Row>


      </InstantSearch>
    </Layout>
  );
}

function Hit(props) {
  return (
    <Link to={`/temple/${props.hit.temple_id}`} key={props.hit.temple_id}>
      <div className="temple-card">
        <div className="temple-card-left">
          <h3><Highlight attribute="temple_c_name" hit={props.hit} /> {props.hit.vegetarian_food_provided && <span className="veg_food"><FaLeaf className="veg_food_icon" /> 供應齋菜</span>}
          </h3>
          <p>{props.hit.address_c}</p>
          <div className="temple-tag-container">
            {props.hit.pray_category && props.hit.pray_category.map((category, index) => {
              return (<span className="temple-tag" key={`category-${index}`}>{category}</span>)
            })}
            {props.hit.deity_up && props.hit.deity_up.map((deity, index) => {
              return (<span className="temple-tag" key={`deity-${index}`}>{deity}</span>)
            })}
          </div>
        </div>
        <div className="temple-card-right">
          {/* <img src={cardcover} alt={props.hit.temple_c_name} /> */}
          <span className="temple-location-tag">{props.hit.location_up}</span>
        </div>
      </div>
    </Link>
  );
}

Hit.propTypes = {
  hit: PropTypes.object.isRequired,
};



export default App;


export const Head = () => (
  <Seo
    title="廟宇大全"
    description="提供全球各大廟宇資訊的網站，旨在讓人們更好地了解和探索傳統文化。我們收錄了各地的廟宇信息，包括奉神靈、服務、廟宇簡介、地址、地圖等。透過這個平台，人們可以方便地查找到自己感興趣的廟宇，深入了解其歷史、文化和價值。此外，也提供了相關的活動和導覽，讓人們能夠更快捷方便地查閱廟宇資料。"
  />
)


