import React, { useState } from 'react';
import { BsChevronCompactLeft, BsChevronCompactRight } from 'react-icons/bs';
import "./slideshow.scss"


const Slideshow = () => {
  const [activeIndex, setActiveIndex] = useState(0);
  const handleNext = () => {
    setActiveIndex((activeIndex + 1) % images.length);
  };

  const handlePrev = () => {
    setActiveIndex((activeIndex + images.length - 1) % images.length);
  };
  const images = [
    "https://s3.ap-southeast-1.amazonaws.com/assets.m2gether.com/large_Cover_Banner_5030031f64.jpg",];

  return (
    <div className="slideshow">
      <div className="slideshow-content">
        <h1>廟宇大全</h1>
        全港最齊全的廟宇資訊平台
      </div>
      <div className="slideshow-image" style={{ backgroundImage: `url(${images[activeIndex]})` }}>
        <button className="prev" onClick={handlePrev} aria-label="Previous slide">
          <BsChevronCompactLeft />
        </button>
        <button className="next" onClick={handleNext} aria-label="Next slide">
          <BsChevronCompactRight />
        </button>

      </div>
    </div>
  );
};

export default Slideshow;